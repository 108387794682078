import { Link } from "@chakra-ui/react";
import { Flex, HStack, Text } from "@chakra-ui/layout";
import React from "react";
import { useSanityAuth } from "../sanityAuth/useSanityAuth";
import { usePreviewContext } from "./previewContext";
import { somePreviewID } from "./SOMEPreview";
import NextLink from "next/link";
import { logEvent } from "../../utils/analytics/Plausible";

function PreviewBanner() {
  const context = usePreviewContext();
  const authStatus = useSanityAuth();

  if (!context.isOn || authStatus.loading) {
    return null;
  }

  return (
    <Flex
      justifyContent="center"
      backgroundColor="hsl(0, 100%, 38%, 0.6)"
      color="white"
      fontSize="0.8rem"
      padding="0.25rem"
      fontWeight="600"
      position="sticky"
      top="0"
      zIndex="1000"
    >
      {authStatus.isAuthenticated ? (
        <HStack>
          <NextLink href={`#__next`} passHref>
            <Link>Forhåndsvisning</Link>
          </NextLink>
          <NextLink href={`#${somePreviewID}`} passHref>
            <Link onClick={() => logEvent("SOME-preview-link")}>Sosiale medier</Link>
          </NextLink>
        </HStack>
      ) : (
        <Text>
          Du må <Link href="/cms/">logge inn</Link> for forhåndsvisning
        </Text>
      )}
    </Flex>
  );
}

export default PreviewBanner;
