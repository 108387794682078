import { isProduction } from "./isProduction";

export const featureToggleEnabled = (toggleKey: keyof typeof featureToggles) => featureToggles[toggleKey]();

const featureToggles = {
  statistikk: () => queryParam("statistikk"),
  ansatte: () => queryParam("ansatte"),
};

type Environment = "development" | "staging" | "production";
const isDevelopment = process.env.NODE_ENV === "development";

/**
 * @param environments feks, isEnv('development', 'staging')
 */
export const isEnv = (...environments: Environment[]): boolean => {
  if (environments.includes("development") && isDevelopment) return true;
  if (environments.includes("production") && isProduction) return true;
  if (environments.includes("staging") && !isDevelopment && !isProduction) return true;
  return false;
};

/**
 * @param queries feks, queryParam('mintoggle'). Kan da skurs på ved å sende med queryparam i url "?ft=mintoggle"
 */
const queryParam = (...queries: string[]): boolean => {
  const flags = getEnabledFeatures();
  if (flags.includes("all")) return true; // Kan alltid skru på alle toggles ved å skrive ?ft=all i url
  if (queries.some((query) => flags.includes(query))) return true; // Kan sende med feks ?ft=statistikk i url
  return false;
};

const sessionStorageKey = "feature-toggles";

export const storeFeatureFlags = () => {
  if (typeof window === "undefined" || typeof sessionStorage === "undefined") return; // For SSR in nextjs

  const searchParams = new URLSearchParams(location.search);
  const flags = searchParams.getAll("ft");

  sessionStorage.setItem(sessionStorageKey, JSON.stringify(flags));
};

export const getEnabledFeatures = (): string[] => {
  if (typeof window === "undefined" || typeof sessionStorage === "undefined") return []; // For SSR in nextjs

  const storedFlags = sessionStorage.getItem(sessionStorageKey);
  return storedFlags ? JSON.parse(storedFlags) : [];
};
