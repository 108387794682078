import { Documents } from "@libry-content/types";
import { objectKeys } from "./types";

export type EditableType = Extract<
  Documents["_type"],
  | "site"
  | "library"
  | "event"
  | "service"
  | "list"
  | "staticPage"
  | "privacyPolicy"
  | "footerMenu"
  | "sommerles"
  | "alert"
  | "recommendation"
>;

// Use a record in order to make sure all types are included through ts
const editableTypesRecord: Record<EditableType, true> = {
  site: true,
  library: true,
  event: true,
  service: true,
  list: true,
  staticPage: true,
  privacyPolicy: true,
  footerMenu: true,
  sommerles: true,
  alert: true,
  recommendation: true,
};

const EDITABLE_TYPES = objectKeys(editableTypesRecord);

export const isEditableType = (typeName: string): typeName is EditableType =>
  (EDITABLE_TYPES as string[]).includes(typeName);

export type EditableDocument = { _id: string; _type: EditableType };

export const getEditDocumentUrl = (doc: EditableDocument) => {
  switch (doc._type) {
    case "site":
      return `/cms/desk/site;site`;
    case "privacyPolicy":
      return `/cms/desk/site;privacyPolicy`;
    case "alert":
      return `/cms/desk/alert`;
    case "sommerles":
      return `/cms/desk/sommerles`;
    default:
      return `/cms/intent/edit/id=${doc._id}`;
  }
};

export type CreateNewableType = Exclude<EditableType, "site" | "privacyPolicy" | "footerMenu" | "sommerles" | "alert">;

// Use a record in order to make sure all types are included through ts
const createNewableTypesRecord: Record<CreateNewableType, true> = {
  library: true,
  event: true,
  service: true,
  list: true,
  staticPage: true,
  recommendation: true,
};

const CREATE_NEWABLE_TYPES = objectKeys(createNewableTypesRecord);

export const isCreateNewableType = (typeName: string): typeName is CreateNewableType =>
  (CREATE_NEWABLE_TYPES as string[]).includes(typeName);

export const getCreateDocumentUrl = (type: CreateNewableType, newDocumentId: string) => {
  // Template-delen må enkodes for å fungere med forhåndsvisning
  const urlEncodedTemplate = encodeURIComponent(`,template=${type}`);
  return `/cms/desk/${type};${newDocumentId}${urlEncodedTemplate}`;
};
