function requireEnv(key: string, value: string | undefined): string {
  if (!value) {
    console.error(`🚦🛑 Environment variable ${key} is empty or undefined`);
    return "";
  }
  return value;
}

const platformDomain = requireEnv("SANITY_STUDIO_PLATFORM_DOMAIN", process.env.SANITY_STUDIO_PLATFORM_DOMAIN);
const protocol = platformDomain.match(/localhost/) ? "http" : "https";

// Note: For the NextJS compiler to find environment variables, they cannot be accessed
// using object destructuring or any other way than process.env.NAME.
export const platform = {
  /**
   * Protocol: Either "http" or "https"
   */
  protocol,

  /**
   * Host: The host part of the base platform URL, consists of the domain name and optionally a port number.
   * Examples: "bibliotek.io", "pr133.qa.bibliotek-test.io", "localhost:3000"
   */
  host: platformDomain,

  /**
   * Hostname: Only the domain name part of the base platform URL, not including port numbers.
   * Examples: "bibliotek.io", "pr133.qa.bibliotek-test.io", "localhost"
   */
  hostname: platformDomain.split(":")[0],

  /**
   * The base platform url for the current deployment. Includes port number for localhost deployments.
   * Example values: 'https://bibliotek.io', 'https://pr133.qa.bibliotek-test.io', 'http://localhost:3000'
   */

  /**
   * The platform domain for the current deployment. Never includes port number.
   * Example values: 'bibliotek.io', 'pr133.qa.bibliotek-test.io', 'localhost'
   */
};

export const getPlatformUrl = (subdomain?: string | undefined): string =>
  subdomain ? `${platform.protocol}://${subdomain}.${platform.host}` : `${platform.protocol}://${platform.host}`;
