import { ClientConfig } from "@sanity/client";
import fetch from "cross-fetch";

export type PermissionResource = {
  id: string;
  name: string;
  title: string;
  description: string;
  config: {
    filter: string;
  };
  isCustom?: boolean;
  permissionResourceType?: string;
};

export type PermissionResourceInput = Omit<PermissionResource, "id" | "isCustom">;

type GrantType =
  | "sanity.document.filter.mode"
  | "sanity.document.filter"
  | "sanity.project"
  | "sanity.project.members"
  | "sanity.project.datasets"
  | "sanity.project.roles"
  | "sanity.project.tags"
  | "sanity.project.usage"
  | "sanity.project.cors"
  | "sanity.project.graphql";

export type PermissionName = "create" | "read" | "update" | "delete" | "mode" | "manage" | "deployStudio";

type PermissionMode = "read" | "update" | "publish";

export type GrantParams = {
  mode?: PermissionMode;
  dataset?: string;
  history?: boolean;
};

export type GrantInput = {
  roleName: string;
  permissionName: PermissionName;
  permissionResourceId: string;
  params?: GrantParams;
};

export type GrantsInput = Omit<GrantInput, "roleName">[];

export type Grant = {
  name: PermissionName; // samme som Grant.permissionName
  params?: GrantParams;
};

export type PermissionResourceGrant = {
  id: string; // Samme som Grant.permissionResourceId
  name: string; // Samme som Grant.permissionResourceName
  title?: string; // for permissionResource
  description?: string; // for permissionResource
  isCustom: boolean;
  grants: Grant[];
};

export interface RoleInput {
  title: string;
  name: string;
  description: string;
}

export type Role = RoleInput & {
  isCustom: boolean;
  projectId: string;
  appliesToUsers: boolean;
  appliesToRobots: boolean;
  grants: {
    [grantType in GrantType]?: PermissionResourceGrant[];
  };
};

class SanityRestClient {
  headers: Record<string, string>;
  baseUrl: string;

  constructor({ headers, baseUrl }: { headers?: Record<string, string>; baseUrl?: string } = {}) {
    this.baseUrl = baseUrl ?? "";
    this.headers = headers ?? {};
  }

  request(endpoint: string, options: RequestInit = {}) {
    const url = this.baseUrl + endpoint;
    return fetch(url, {
      credentials: "include",
      mode: "cors",
      ...options,
      headers: {
        ...this.headers,
        ...options.headers,
      },
    });
  }

  async jsonRequest<TResponse>(endpoint: string, options: RequestInit = {}): Promise<TResponse> {
    const response = await this.request(endpoint, {
      ...options,
      headers: {
        ...options.headers,
        "Content-Type": "application/json",
      },
    });
    if (!response.ok) {
      throw new Error(`Got ${response.status} response from Sanity API: ${await response.text()}`);
    }
    return (await response.json()) as TResponse;
  }

  async findOrNull<TResponse>(endpoint: string, options: object = {}): Promise<TResponse | null> {
    const response = await this.request(endpoint, options);
    if (response.ok) {
      return (await response.json()) as TResponse;
    }
    if (response.status == 404) {
      return null;
    }
    throw new Error(`Got ${response.status} response from Sanity API: ${await response.text()}`);
  }

  post<TResponse>(endpoint: string, data: object) {
    return this.jsonRequest<TResponse>(endpoint, { method: "POST", body: JSON.stringify(data) });
  }

  put<TResponse>(endpoint: string, data: object) {
    return this.jsonRequest<TResponse>(endpoint, { method: "PUT", body: JSON.stringify(data) });
  }

  delete(endpoint: string, data: object | undefined = undefined) {
    return this.request(endpoint, {
      method: "DELETE",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
      },
    });
  }
}

export class SanityManageClient {
  permissionResources: PermissionResourceCollection;
  grants: GrantCollection;
  roles: RoleCollection;
  projects: ProjectClient;
  users: UserCollection;
  datasets: DatasetCollection;

  constructor(config: ClientConfig = {}) {
    const { projectId, apiVersion, token } = config;
    const headers: Record<string, string> = token ? { Authorization: `Bearer ${token}` } : {};

    // Organization resources
    const baseClient = new SanityRestClient({ headers, baseUrl: `https://${projectId}.api.sanity.io/v${apiVersion}/` });
    this.projects = new ProjectClient(baseClient);
    this.users = new UserCollection(baseClient);

    // Project resources
    const projectClient = new SanityRestClient({
      headers,
      baseUrl: `https://${projectId}.api.sanity.io/v${apiVersion}/projects/${projectId}/`,
    });
    this.permissionResources = new PermissionResourceCollection(projectClient);
    this.grants = new GrantCollection(projectClient);
    this.roles = new RoleCollection(projectClient);
    this.datasets = new DatasetCollection(projectClient);
  }
}

export class PermissionResourceCollection implements SanityProjectResourceCollection {
  client: SanityRestClient;

  constructor(client: SanityRestClient) {
    this.client = client;
  }

  async create({ permissionResourceType = "sanity.document.filter.mode", ...rest }: PermissionResourceInput) {
    const resource = await this.client.post<PermissionResource>("permissionResources", {
      permissionResourceType,
      ...rest,
    });
    console.log(`Created permission resource: ${resource.id}`, resource);
    return resource;
  }

  get(name: string) {
    return this.client.findOrNull<PermissionResource>(`permissionResources/${name}`);
  }

  list() {
    return this.client.jsonRequest<PermissionResource[]>("permissionResources");
  }

  async update(id: string, input: Omit<PermissionResourceInput, "name" | "permissionResourceType">) {
    const resource = await this.client.put<PermissionResource>(`permissionResources/${id}`, input);
    console.log(`Updated permission resource: ${resource.id}`, resource);
    return resource;
  }

  async delete(name: string) {
    const response = await this.client.delete(`permissionResources/${name}`);
    if (!response.ok) {
      const responseData = await response.json();
      throw new Error(`Could not delete permission resource, got ${response.status} response: ${responseData.message}`);
    }
    console.info(`Permission resource deleted. permission_resource=${name}`);
  }
}

class GrantCollection {
  client: SanityRestClient;

  constructor(client: SanityRestClient) {
    this.client = client;
  }

  create(grant: GrantInput) {
    return this.client.post<GrantInput>("grants", grant);
  }

  createMany(grants: GrantsInput, role: Role) {
    return this.client.post<GrantInput[]>("grants", { grants, roleName: role.name });
  }

  deleteMany(grants: GrantsInput, role: Role) {
    return this.client.delete("grants", { grants, roleName: role.name });
  }
}

export interface SanityResource {
  name: string;
}

export interface SanityProjectResourceCollection {
  list(): Promise<SanityResource[]>;
  delete(name: string): Promise<void>;
}
class RoleCollection implements SanityProjectResourceCollection {
  client: SanityRestClient;

  constructor(client: SanityRestClient) {
    this.client = client;
  }

  async create(definition: RoleInput) {
    const role = await this.client.post<Role>("roles", definition);
    console.log(`Created role: ${role.name}`, role);
    return role;
  }

  list() {
    return this.client.jsonRequest<Role[]>("roles");
  }

  get(name: string) {
    return this.client.findOrNull<Role>(`roles/${name}`);
  }

  async update(name: string, input: Omit<RoleInput, "name">) {
    const resource = await this.client.put<Role>(`roles/${name}`, input);
    console.log(`Updated role: role_name=${resource.name}`, resource);
    return resource;
  }

  async delete(name: string) {
    const response = await this.client.delete(`roles/${name}`);
    if (response.status === 404 || response.status === 500) {
      // This is a workaround, see CMS-406
      // Sanity started returning 404 on March 22, but still deletes the role.
      // We should remove this condition once Sanity starts returning 2XX responses again.
      console.info(`Role probably deleted. role_name=${name}`);
    } else if (!response.ok) {
      const responseData = await response.json();
      throw new Error(`Could not delete role: ${responseData.message}`);
    }
    console.info(`Role deleted. role_name=${name}`);
  }
}

export interface ProjectMember {
  id: string;
  createdAt: Date;
  updatedAt: Date;
  isCurrentUser: boolean;
  isRobot: boolean;
  roles: RoleInput[];
}

export interface Project {
  id: string;
  displayName: string;
  studioHost?: any;
  isBlocked: boolean;
  isDisabled: boolean;
  isDisabledByUser: boolean;
  metadata: {
    externalStudioHost: string;
  };
  maxRetentionDays: number;
  activityFeedEnabled: boolean;
  createdAt: Date;
  updatedAt: Date;
  organizationId: string;
  members: ProjectMember[];
  features: string[];
  pendingInvites: number;
}

class ProjectClient {
  client: SanityRestClient;

  constructor(client: SanityRestClient) {
    this.client = client;
  }

  get(name: string) {
    return this.client.findOrNull<Project>(`projects/${name}`);
  }
}

export interface User {
  id: string;
  displayName?: string;
  familyName?: string;
  givenName?: string;
  middleName?: any;
  imageUrl?: string;
  provider?: string;
  projectId?: string;
  createdAt?: Date;
  updatedAt?: Date;
  sanityUserId?: string;
  email?: string;
}

class UserCollection {
  client: SanityRestClient;

  constructor(client: SanityRestClient) {
    this.client = client;
  }

  get(ids: string[]) {
    return this.client.findOrNull<User[]>(`users/${ids.join(",")}`) || [];
  }
}

export interface DatasetTag {
  name: string;
  title: string;
}

export interface Dataset {
  name: string;
  aclMode: string;
  createdAt: Date;
  createdByUserId: string;
  tags: DatasetTag[];
}

class DatasetCollection implements SanityProjectResourceCollection {
  client: SanityRestClient;

  constructor(client: SanityRestClient) {
    this.client = client;
  }

  list() {
    return this.client.jsonRequest<Dataset[]>("datasets");
  }

  async delete(name: string) {
    const response = await this.client.delete(`datasets/${name}`);
    if (!response.ok) {
      const responseData = await response.json();
      throw new Error(`Could not delete dataset: ${responseData.message}`);
    }
    console.info(`Dataset deleted. dataset_name=${name}`);
  }
}
