import { ResolvedSite, resolveSiteGroqProjecton } from "../components/site/sanityQuery";
import { sanityClient } from "./sanity/client";

type BaseProps = {
  query: string;
  queryParams: {
    siteDomain: string;
  };
  data: {};
};

type WithSiteData<Data> = Data & { site: ResolvedSite | null };

/**
 * Fetch results from a GROQ query, rewritten to be filtered by the current site and
 * extended with site data needed by layout elements.
 *
 * @param query GROQ query
 * @param queryParams GROQ query parameters, including `siteDomain` in addition to the parameters in the query
 */
export const fetchSiteQuery = async <Props extends BaseProps>(query: string, queryParams: Props["queryParams"]) => {
  const queryWithSite = query
    // Ensure all queries only return data from the current site:
    .replace(/\* *\[/g, "*[ site->domain == $siteDomain && ")
    // Add data about the current site needed by layout elements (header, footer, etc.) through SiteContext:
    .replace(/^{/, `{"site": *[_type == "site" && domain == $siteDomain][0]{ ${resolveSiteGroqProjecton} }, `);

  const data: Props["data"] = await sanityClient.fetch(queryWithSite, queryParams);
  return { data, query: queryWithSite, queryParams };
};

export interface SitePageProps<ResponseData = {}, QueryParams = {}> {
  data: WithSiteData<ResponseData>;
  query: string;
  queryParams: QueryParams & {
    siteDomain: string;
  };
}
