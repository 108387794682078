import { BiblioteksentralenProvider } from "@biblioteksentralen/js-utils";
import { storeFeatureFlags } from "@libry-content/common";
import { AppProps } from "next/app";
import React, { ReactNode } from "react";
import { useMount } from "react-use";
import { EditInSanityContextProvider } from "../components/editInSanity/EditInSanityContext";
import { PreviewContextProvider } from "../components/preview/previewContext";
import { SOMEPreview } from "../components/preview/SOMEPreview";
import Testenvironmentbanner from "../components/TestEnvironmentBanner";
import { PlausibleSetup } from "../utils/analytics/Plausible";
import { logVercelInfo } from "../utils/logVercelInfo";

function Providers(props: { children: ReactNode }) {
  return (
    <PreviewContextProvider>
      <BiblioteksentralenProvider>
        <EditInSanityContextProvider>{props.children}</EditInSanityContextProvider>
      </BiblioteksentralenProvider>
    </PreviewContextProvider>
  );
}

function App({ Component, pageProps }: AppProps) {
  useMount(() => {
    logVercelInfo();
    storeFeatureFlags();
  });

  return (
    <Providers>
      <PlausibleSetup />
      <Testenvironmentbanner />
      <Component {...pageProps} />
      <SOMEPreview />
    </Providers>
  );
}

export default App;
