import {
  Library,
  Site,
  FooterMenu,
  SanityKeyed,
  SocialMedia,
  StaticPage,
  ExternalLink,
  Sommerles,
} from "@libry-content/types";
import groq from "groq";
import { Modify } from "../../utils/typeUtils";

export const resolveSiteGroqProjecton = groq`
    ...,
    'libraries': *[_type == 'library' && site._ref == ^._id]{
      slug
    },
    'footerMenus': *[_type == 'footerMenu' && site._ref == ^._id]{
      _id,
      _type,
      index,
      menuHeader,
      'menuItems': menuItems[]{
        _type == 'reference' => @->,
        _type != 'reference' => @,
      }
    } | order(index asc),
    'bookListCount': count(*[_type == "list" && site._ref == ^._id]),
    'mainLibrarySocialMedia': mainLibrary->socialMedia,
    'bookListIds': *[_type == "list" && site._ref == ^._id] | order(_createdAt desc)[]._id,
    'recommendationCount': count(*[_type == "recommendation" && site._ref == ^._id]),
    'recommendationIds': *[_type == "recommendation" && site._ref == ^._id] | order(_createdAt desc)[]._id,
    'sommerles': *[_type == "sommerles" && site._ref == ^._id][0]{ _id, _type, active, separatePage }
  `;

export type FooterMenuItem = SanityKeyed<ExternalLink> | StaticPage;

export type ResolvedFooterMenu = Pick<FooterMenu, "_id" | "_type" | "menuHeader"> & {
  menuItems: FooterMenuItem[];
};

export type ResolvedSite = Modify<
  Site,
  {
    footerMenus: SanityKeyed<ResolvedFooterMenu>[];
    libraries: Pick<Library, "_id" | "slug">[];
    bookListCount: number;
    mainLibrarySocialMedia: SocialMedia;
    bookListIds: string[];
    sommerles?: Pick<Sommerles, "_id" | "_type" | "active" | "separatePage">;
    recommendationCount: number;
    recommendationIds: string[];
  }
>;
